@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
  padding-top: 65px;
  font-family: Roboto;
}

.navbar {
  margin-bottom: 30px;
}

.clickable {
  cursor: pointer;
}

.nav-item.nav-link{
  text-decoration: none;
  color: inherit;
}

.main-container, .container{
  padding: 0px 24px;
  max-width: 1360px;
  margin: auto;
  width: 100%;
}

.container .MuiToolbar-gutters{
	padding: 0;
}

.tableContainerHeader{
	padding: 15px;
}

.tableContainerBody .MuiSvgIcon-root {
	font-size: 15px;
	color: #a1a1a1;
}

.table{
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}

.input-error{
	color: #f44336;
	margin-bottom: 20px;
	margin-top: -15px;
	font-size: 12px;
}

.no-margin-container .input-error{
	margin-top: 10px;
}

.main-menu .MuiListItemIcon-root{
	min-width: auto;
	margin-right: 10px;
}

.align-right{
	text-align: right;
}

.table{
	margin-bottom: 0px;
	width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.table thead{
	display: table-header-group;
	background: #eee;
}

.table thead tr {
    color: inherit;
    display: table-row;
    outline: 0;
	vertical-align: middle;
	border-bottom: 1px solid #e4e4e4;
	border-top: 1px solid #e4e4e4;
}

.table thead tr:hover{
	background-color: inherit;
}

.table thead th{
	text-align: left;
	color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
	line-height: 1.5rem;
	padding: 15px;
}

.table tr{
	border-bottom: 1px solid #e0e0e0;
    font-size: 0.875rem;
    text-align: left;
    vertical-align: inherit;
}
.table tr:hover{
	background-color: rgba(0, 0, 0, 0.04);
}

.table tr td{
	padding: 15px;
}

.table tr td a{
	text-decoration: none;
	color: #2655a6;
}

.table tr td a:hover{
	color: #000;
}

.table .btn-danger, .btn-danger{
	color: #dc004e !important;
}

.table tr .actionColumn{
	width: 40px;
	padding: 15px 5px 15px 0;
}

.table tr .actionColumn .btn-sm{
	min-width: 45px;
}

.btn-sm-5{
	padding: 6px !important;
}

.bg-gray{
	background-color: #efefef !important;
}

.btn-black{
	color: #000;
}

.title-container{
	width: auto;
	display: table;
	padding: 5px 20px;
	background-color: #4caf50;
	color: #fff;
	margin-bottom: 25px;
	box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.title-container h1{
	margin: 0;
	font-size: 20px;
}

.reduce-bottom-5{
	margin-bottom: -5px;
}

.btn-restart.MuiButton-contained.Mui-disabled {
	background-color: #e0e0e0 !important;
	min-width: 212px !important;
}

.menu-popover .MuiPaper-root{
	top: 65px !important;
}

.card-bg-gray.MuiPaper-root{
	background-color: #f7f7f7;
}

.card-bg-gray.MuiPaper-root .MuiInputBase-root.Mui-disabled {
	color: #3b3b3b;
}

.fancy-title svg{
	position: relative;
	top: 5px;
}

.MuiChip-root .MuiChip-avatar.chip-name{
	font-size: 10px;
	font-weight: bold;
	color: #000;
}

h1 svg{
	position: relative;
	top: 3px;
}